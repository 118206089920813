<template>
  <v-dialog v-model="show" scrollable max-width="868px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h6 text--primary">{{ $t("title.template_image_list") }}</span>
        <v-divider class="mx-3 d-none d-md-block" vertical></v-divider>
        <p-text-field
          v-model="searchQuery"
          variant="outlined"
          :label="$t('text.search')"
          prepend-inner-icon="mdi-magnify"
          hide-details
          density="default"
          full-width
        ></p-text-field
      ></v-card-title>
      <v-card-text style="height: 70vh; position: relative">
        <v-row v-if="resultQuery.length > 0">
          <v-col
            v-for="(item, index) in resultQuery"
            :key="index"
            class="d-flex child-flex flex-column pa-2 cursor-pointer"
            cols="2"
            @click="getImage(item)"
          >
            <v-img
              transition="false"
              :src="item.url"
              :lazy-src="item.url"
              aspect-ratio="1"
              class="grey lighten-2 mb-1"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <span class="caption text--primary">{{ item.name }}</span>
          </v-col>
        </v-row>
        <div class="text-center mt-5" v-else>
          条件に一致するデータは見つかりませんでした。
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn variant="text" @click="close">
          {{ $t("text.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { templateImageStore } from '@/store';
import { CONSTANTS } from '@/utils';
import { mapState } from 'pinia';

export default {
  name: "PGTemplateImage",
  props: {
    value: { type: Boolean, default: false },
    getImage: { type: Function, default: () => {} },
  },
  setup() {
    const storeTemplateImage = templateImageStore();
    return {
      storeTemplateImage,
    };
  },
  data() {
    return {
      searchQuery: "",
      resources: [
        // {
        //   file_url: "https://picsum.photos/id/11/10/6",
        //   title: "text",
        // },
      ],
    };
  },
  computed: {
    ...mapState(templateImageStore, ["success", "message", "images"]),
    show: {
      get() {
        if(this.value){
          this.readDataAvatar();
        }
        return this.value;
      },
      set(value) {
       
        this.$emit("input", value);
      },
    },

    resultQuery() {
      if (this.searchQuery) {
        return this.images.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.name.toLowerCase().includes(v));
        });
      } else {
        return this.images;
      }
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    async readDataAvatar() {
      await this.storeTemplateImage.getList({
        folder: CONSTANTS.FOLDER_TEMPLATE.BACKGROUND,
      });
    },
  },
};
</script>

<style></style>
