import { defineStore } from "pinia";
import { URLS, API, handleGetMessageError, CONSTANTS, auth } from "../../utils";
export const userStore = defineStore("users", {
  state: () => ({
    success: true,
    message: "",
    user: null,
    currentUser: null,
    error: null,
    datas: [],
    owner: null,
    listError: [],
    listUser: [],
    listGroupUser: [],
    infoUser: null,
    totalUser: null,
  }),

  actions: {
    async sendMailSignUp(email) {
      await API.requestServer
        .get(`${URLS.SEND_MAIL_SIGNUP}/${email}`)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.user = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async sendMailSignUpNewShare(email, token) {
      await API.requestServer
        .get(`${URLS.SEND_MAIL_SIGNUP_SHARE}/${email}/${token}`)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.user = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async createAccount(request) {
      await API.requestServer
        .post(`${URLS.CREATE_ACCOUNT}`, request)
        .then((response) => {
          const { data } = response;
          this.user = data.data;
          this.success = true;
        })
        .catch((error) => {
          this.success = false;
          this.error = error.response;
          this.message = handleGetMessageError(error);
        });
    },
    async createShareAccount(request) {
      await API.requestServer
        .post(`${URLS.CREATE_SHARE_ACCOUNT}`, request)
        .then((response) => {
          const { data } = response;
          this.user = data.data;
          this.success = true;
        })
        .catch((error) => {
          this.success = false;
          this.error = error.response;
          this.message = handleGetMessageError(error);
        });
    },
    async createUser(companyId, request) {
      await API.requestServer
        .post(`${companyId}/${URLS.USER}`, request)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.user = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async createUserGroup(companyId, request) {
      await API.requestServer
        .post(`${companyId}/${URLS.CREATE_GROUP_USER}`, request)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.user = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async getListUserGroup(companyId) {
      await API.requestServer
        .get(`${companyId}/${URLS.GROUP}`)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.listGroupUser = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async getByGroupUserId(companyId, group_id) {
      await API.requestServer
        .get(`${companyId}/${URLS.GROUP}/${group_id}`)
        .then((response) => {
          const { data } = response;
          this.infoUser = data.data;
          this.success = true;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async updateGroupUser(companyId, group_id, request) {
      await API.requestServer
        .put(`${companyId}/${URLS.UPDATE_GROUP}/${group_id}`, request)
        .then(() => {
          this.success = true;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },

    async updateGameList(companyId, game_id, request) {
      await API.requestServer
        .put(`${companyId}/${URLS.UPDATE_GAME_LIST}/${game_id}`, request)
        .then(() => {
          this.success = true;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async updateUser(companyId, userId, request) {
      await API.requestServer
        .put(`${companyId}/${URLS.USER}/${userId}`, request)
        .then(() => {
          this.success = true;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async initialSetting(request) {
      await API.requestServer
        .post(`${URLS.INITIAL_SETTING}`, request)
        .then((response) => {
          const { data } = response;
          this.success = true;
          localStorage.setItem(CONSTANTS.TOKEN, data.data);
          const authData = auth();
          localStorage.setItem(CONSTANTS.AUTH_STORAGE, JSON.stringify(authData));
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async updateAccount(request) {
      await API.requestServer
        .post(`${URLS.UPDATE_ACCOUNT}`, request)
        .then(() => {
          // const { data } = response;
          this.success = true;
          // localStorage.setItem(CONSTANTS.TOKEN, data.data);
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async getTotalUser(companyId) {
      await API.requestServer
        .get(`${companyId}/total_${URLS.USER}`)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.totalUser = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async getListUser(companyId) {
      await this.getTotalUser(companyId);
      const pageSize = 10000;
      const totalPages = Math.ceil(this.totalUser / pageSize);
      console.log("totalPages");
      const results = [];

      for (let index = 1; index <= totalPages; index += 3) {
        const batch = [];

        // Prepare a batch of up to 3 requests
        for (let j = 0; j < 3 && index + j <= totalPages; j++) {
          const request = API.requestServer
            .get(`${companyId}/${index + j}/${URLS.USER}`)
            .then((response) => response.data.data)
            .catch((error) => {
              console.error(`Error fetching page ${index + j}:`, error);
              return null; // Mark failed requests with null
            });
          batch.push(request);
        }

        // Wait for the current batch to complete before proceeding
        const batchResults = await Promise.allSettled(batch);

        // Retry failed requests
        for (let i = 0; i < batchResults.length; i++) {
          const result = batchResults[i];
          if (result.status === "fulfilled" && result.value !== null) {
            results.push(result.value);
          } else if (result.status === "rejected" || result.value === null) {
            // Retry the failed request
            try {
              const retryResponse = await API.requestServer.get(`${companyId}/${index + i}/${URLS.USER}`);
              results.push(retryResponse.data.data);
            } catch (retryError) {
              console.error(`Retry failed for page ${index + i}:`, retryError);
              this.message = handleGetMessageError(retryError);
            }
          }
        }
      }
      // Flatten the results array and update the state
      this.datas = results.flat();
      this.success = true;
    },
    async getOwner(companyId) {
      await API.requestServer
        .get(`${companyId}/${URLS.ONWER}`)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.owner = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async getOwnerShare(companyId) {
      await API.requestServer
        .get(`/auth/${companyId}/${URLS.ONWER_SHARE}`)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.owner = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async findById(companyId, id) {
      await API.requestServer
        .get(`${companyId}/${URLS.USER}/${id}`)
        .then((response) => {
          const { data } = response;
          this.user = data.data;
          this.success = true;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async deleteUser(companyId, id) {
      await API.requestServer
        .delete(`${companyId}/${URLS.USER}/${id}`)
        .then(() => {
          this.success = true;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async importUser(companyId, request) {
      await API.requestServer
        .post(`${companyId}/${URLS.USER}/${URLS.IMPORT}`, request)
        .then((response) => {
          this.success = true;
          const { data } = response;
          this.listError = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    setSuccess(s) {
      this.success = s;
    },
    setUser(data) {
      this.user = data;
    },
    async checkToken(token, email) {
      await API.requestServer
        .get(`/auth/check-token/${token}/${email}`)
        .then(() => {
          this.success = true;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async checkAdminUser(request) {
      await API.requestServer
        .post(`${URLS.CHECK_ADMIN_USER}`, request)
        .then((response) => {
          const { data } = response;
          this.listUser = data.data;
          this.success = true;
        })
        .catch((error) => {
          this.success = false;
          this.error = error.response;
          this.message = handleGetMessageError(error);
        });
    },
    async checkAutoAdminUser(request) {
      await API.requestServer
        .post(`${URLS.CHECK_AUTO_ADMIN_USER}`, request)
        .then((response) => {
          const { data } = response;
          this.listUser = data.data;
          this.success = true;
        })
        .catch((error) => {
          this.success = false;
          this.error = error.response;
          this.message = handleGetMessageError(error);
        });
    },
    async importGroupUser(companyId, request) {
      await API.requestServer
        .post(`${companyId}/${URLS.GROUP}/${URLS.IMPORT}`, request)
        .then((response) => {
          this.success = true;
          const { data } = response;
          this.listError = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async deleteMultipleGroupUser(companyId, request) {
      await API.requestServer
        .post(`${companyId}/${URLS.GROUP}/${URLS.MULTIPLE}`, request)
        .then(() => {
          this.success = true;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async deleteMultipleUser(companyId, request) {
      await API.requestServer
        .post(`${companyId}/${URLS.USER}/${URLS.MULTIPLE}`, request)
        .then(() => {
          this.success = true;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
  },
});
