<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    scrollable
    transition="dialog-top-transition"
  >
    <div class="bg-imge-main">
      <v-toolbar class="d-flex justify-center">
        <div class="text-center">
          <v-img
            transition="false"
            :src="require('@/assets/logo-pinto-game.svg')"
            class="my-3 cursor-pointer"
            width="155"
            height="48"
          />
        </div>
      </v-toolbar>
      <div class="w-md-70 mt-5 mx-auto">
        <template v-if="loading">
          <div>
            <v-alert type="info" width="100%" icon="mdi-information-outline"
              ><p class="mb-0">決済処理中です。</p>
              <p class="mb-0">画面を閉じずにそのままおまちください。</p></v-alert
            >
          </div>
          <div class="d-flex align-center justify-center" style="height: 70vh">
            <v-progress-circular
              :size="120"
              :width="10"
              color="secondary"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>

        <template v-else>
          <template v-if="success">
            <v-card v-if="ischeckDownGrade">
              <v-card-title>申し込みが完了しました。</v-card-title>
              <v-card-text>
                <v-alert type="success" width="100%" icon="mdi-checkbox-marked-circle"
                  ><p class="mb-0">来月よりプランが変更されます。</p>
                </v-alert>
              </v-card-text>
              <v-card-actions class="justify-center">
                <div>
                  <v-btn
                    variant="outlined"
                    color="primary"
                    class="mx-2"
                    selected-class="btn-c-active"
                    link
                    @click="backAccountInfo"
                    >{{ $t("title.account_info") }}</v-btn
                  >
                </div>
              </v-card-actions>
            </v-card>
            <v-card v-else>
              <v-card-title>決済処理が完了しました。</v-card-title>
              <v-card-text>
                <v-alert type="success" width="100%" icon="mdi-checkbox-marked-circle"
                  ><p class="mb-0">決済処理が完了しました。</p>
                  <p class="mb-0">
                    ご請求内容は「請求履歴」よりご確認いただけます。
                  </p></v-alert
                >
              </v-card-text>
              <v-card-actions class="justify-center">
                <div>
                  <v-btn
                    variant="outlined"
                    color="primary"
                    class="mx-2"
                    active-class="btn-c-active"
                    link
                    @click="backAccountInfo"
                    >{{ $t("title.account_info") }}</v-btn
                  ><v-btn
                    variant="outlined"
                    color="primary"
                    class="mx-2"
                    link
                    to="/account-info/payment-history"
                    >請求履歴へ</v-btn
                  >
                </div>
              </v-card-actions>
            </v-card>
          </template>
          <template v-else>
            <v-card>
              <v-card-text>
                <v-alert
                  type="error"
                  width="100%"
                  icon="mdi-alert-octagon-outline"
                  v-if="error && error?.status == 402"
                  > 
                  <!-- {{ error.data.message }} -->
                  <p class="mb-0">カード発行会社により決済が拒否されました。</p>
                  <p class="mb-0">
                    詳細はクレジットカード会社にお問い合わせください。
                  </p></v-alert
                >
                <v-alert type="error" width="100%" icon="mdi-alert-octagon-outline" v-else
                  > 
                  <!-- {{ error.data.message }} -->
                  <p class="mb-0">決済処理に失敗しました。</p>
                  <p class="mb-0">
                    お手数ですが、時間を空けてから再度お試しください。
                  </p></v-alert
                >
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn variant="outlined" class="mx-2" @click="close">プラン変更へ戻る</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </template>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "PGPaymentLoading",
  props: {
    value: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    success: { type: Boolean, default: false },
    error: { type: Object, default: null },
    ischeckDownGrade: {type: Boolean, default : false},
  },
  data() {
    return {};
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    backAccountInfo() {
      this.$router.push({ path: "/account-info" });
    },
    close() {
      this.$emit("emitPaymentError" , true);
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
