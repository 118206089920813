<template>
  <v-select
    class="p-select custom"
    v-bind="$attrs"
    :items="localItems"
    :color="color"
    :item-color="itemColor"
  >
    <template v-for="(value, name) in $slots" v-slot:[name]>
      <slot :name="name"></slot>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "PGSelect",
  props: {
    color: {
      type: String,
      default: "secondary",
    },
    itemColor: {
      type: String,
      default: "secondary",
    },
    items: { type: Array, default: () => [] },
  },

  watch: {
    items: {
      handler(values) {
        if (values?.length) {
          const mapItems = values.map((item) => {
            const title = item?.text ? item.text : item;
            return {
              ...item,
              title,
            };
          });
          this.localItems = mapItems;
        }
      },
      immediate: true,
    },
  },

  data() {
    return {
      localItems: [],
    };
  },
};
</script>

<style lang="scss" >
  .custom .v-select__selection--comma{
    overflow: inherit;
  }
</style>>

