import { COLLECTION_FIREBASE, CONSTANTS, REGEX } from "./constans";
import { URLS } from "./urls";
import { API } from "./https-common";
import {
	handleGetMessageError,
	handleGetIsShowMessageError,
} from "./handle-error";
import { authRouter, restrictRoute } from "./authRouter";
import { readFileXLSX, exportXLSX, exportCSV, exportAOA, downLoadLoginRate, errorFileName, exportMultiSheet, errorAudioFileName } from "./readFile";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { enc,lib, AES, mode, pad } from 'crypto-js';

const auth = () => {
	const token = window.localStorage.getItem(CONSTANTS.TOKEN);
	if (token) {
		const decodedJwt = jwtDecode(token);
		return decodedJwt;
	}
	return null;
};
const encryptedUrl = (url) => {
	// Convert the secret key to a CryptoJS object
	const key = enc.Utf8.parse(CONSTANTS.SECRETKEY);
	// Generate a random initialization vector (IV)
	const iv = lib.WordArray.random(16);
	// Encrypt the plaintext with AES-CBC and the secret key
	const ciphertext = AES.encrypt(url, key, {
		iv: iv,
		mode: mode.CBC,
	});
	// Combine the IV and ciphertext into a single string
	const encryptedUrl = iv.concat(ciphertext.ciphertext).toString(enc.Base64);
	return `${process.env.VUE_APP_URL_APP}${encryptedUrl}`;
	// return `${process.env.VUE_APP_URL_APP}${url}`;
};

const decryptedUrl = (encryptedUrl) => {
	const encryptedUrlRevert = encryptedUrl.replace('xMl3Jk', '+' ).replace('Por21Ld', '/').replace('Ml32', '=')
	// Convert the secret key to a CryptoJS object
	const key = enc.Utf8.parse(CONSTANTS.SECRETKEY);

	// Convert the encryptedUrl from Base64 to a WordArray
	const encryptedBytes = enc.Base64.parse(encryptedUrlRevert);

	// Extract the initialization vector (IV) from the encrypted data
	const iv = lib.WordArray.create(encryptedBytes.words.slice(0, 4), 16);

	// Decrypt the ciphertext with AES-CBC and the secret key
	const decrypted = AES.decrypt({
		ciphertext: lib.WordArray.create(encryptedBytes.words.slice(4))
	}, key, {
		iv: iv,
		mode: mode.CBC,
		padding: pad.Pkcs7
	});

	// Convert the decrypted data to a string
	const decryptedUrlString = decrypted.toString(enc.Utf8);

	return decryptedUrlString;
};

function imageSize(url) {
	const img = document.createElement("img");
	const promise = new Promise((resolve, reject) => {
		img.onload = () => {
			// Natural size is the actual image size regardless of rendering.
			// The 'normal' `width`/`height` are for the **rendered** size.
			const width = img.naturalWidth;
			const height = img.naturalHeight;
			// Resolve promise with the width and height
			resolve({ width, height });
		};
		// Reject promise on error
		img.onerror = reject;
	});
	// Setting the source makes it start downloading and eventually call `onload`
	img.src = url;

	return promise;
}
function getSizeFile(_size, withUnit = true) {
	const fSExt = new Array("Bytes", "KB", "MB", "GB");
	let i = 0;
	while (_size > 900 && i < fSExt.length - 1) {
		_size /= 1024;
		i++;
	}
	const result = Math.round(_size * 100) / 100;
	const resultString = formattedNumber(result);
	return withUnit ? resultString + " " + fSExt[i] : resultString;
}

function convertByteToGB(size, max_size ,fixed = 2) {
	if (max_size <= 1024 * 1024 * 100) {
		const MB = size / 1024 / 1024;
		return parseFloat(MB).toFixed(fixed) + " " + "MB";
	}
	const GB = size / 1024 / 1024 / 1024;
	const result = parseFloat(GB).toFixed(fixed);
	const resultString = formattedNumber(result);

	return resultString + " " + "GB";
}

function CapacityUnit(size) {
	const units = ['B', 'KB', 'MB', 'GB'];
	let unitIndex = 0;
  
	while (size >= 1024 && unitIndex < units.length - 1) {
		size /= 1024;
		unitIndex++;
	}
	return `${size.toFixed(2)} ${units[unitIndex]}`;
}
// function getTimes(date) {
//   const start = moment().isSame(moment(date), "d") ? moment().hours() : 1;
//   const length = 10;
//   let startMin = start * 60;
//   let endMin = 24 * 60;
//   let times = [];

//   while (startMin <= endMin) {
//     let mins = startMin % 60;
//     let hours = Math.floor(startMin / 60);
//     const hoursString = hours < 10 ? `0${hours}` : hours == 24 ? "00" : hours;
//     let timeString = hoursString + ":" + mins.toString().padStart(2, "0");
//     if (hours == start && mins > moment().minutes()) {
//       times.push(timeString);
//     } else if (hours > start) {
//       times.push(timeString);
//     }
//     startMin += length;
//   }

//   return times;
// }
function getTimes() {
	const start = 0;
	const end = 24;
	const length = 10;
	let startMin = start * 60;
	let endMin = end * 60;
	let times = [];

	while (startMin <= endMin) {
		let mins = startMin % 60;
		let hours = Math.floor(startMin / 60);
		const hoursString = hours < 10 ? `0${hours}` : hours;
		let timeString = hoursString + ":" + mins.toString().padStart(2, "0");
		if (timeString != "24:00") {
			if (hours == start) {
				times.push(timeString);
			} else if (hours > start) {
				times.push(timeString);
			}
		}
		startMin += length;
	}
	return times;
}
function getNextTime() {
	const now = new Date();
	const currentMin = now.getHours() * 60 + now.getMinutes(); // Convert current time to minutes since midnight

	const start = 0;
	const end = 24;
	const length = 10; // Interval of 10 minutes
	let startMin = start * 60;
	let endMin = end * 60;
	let times = [];

	while (startMin <= endMin) {
		if (startMin >= currentMin) {
			let mins = startMin % 60;
			let hours = Math.floor(startMin / 60);
			const hoursString = hours < 10 ? `0${hours}` : hours;
			let timeString = hoursString + ":" + mins.toString().padStart(2, "0");
			if (timeString != "24:00") { // Exclude "24:00" if it's not wanted
				times.push(timeString);
				break; // Stop after adding the closest time greater than the current time
			}
		}
		startMin += length;
	}
	
	return times.length > 0 ? times[0] : "No more times available today"; // Return only the next time or a message if no more times are available
}
function getTimeItems(formDate) {
  const items = [];
  const today = new Date();
  const reservDate = moment(formDate ?? today).isSame(
    today,
    "d"
  )
    ? today
    : formDate;

  const check = moment(reservDate).isAfter(today);

  const start = check
    ? moment(today).hour(0).minute(0)
    : moment(reservDate);
  const end = moment(start.clone()).hour(23).minute(50);
  const clone = start.clone().minute(0);

  while (clone.isSameOrBefore(end)) {
    if (clone.isAfter(start)) {
      items.push(clone.clone().format("HH:mm"));
    }
    clone.add(10, "m");
  }

  if (check) {
    items.unshift("00:00");
  }

  return items;
}
function formatDate(date) {
	if (!date) return null;
	return moment(date).format("YYYY/MM/DD");
}

function formatDateTime(date) {
	if (!date) return null;
	return moment(date * 1000).format("YYYY/MM/DD HH:mm");
}

function scrollToTop() {
	window.scrollTo(0, 0);
}
function getStatusTime(status, date, time) {
	return status === CONSTANTS.PERIOD_STATUS.PUBLICATION_TIME && date && time
		? `${date} ${time}`
		: null;
}
function validatePublicTimeDate(
	status,
	specify_the_end,
	start_date,
	start_time,
	end_date,
	end_time
) {
	const start_date_new = moment(start_date).format("YYYY-MM-DD")
	const end_date_new = moment(end_date).format("YYYY-MM-DD")
	const startDateTime = moment(
		`${start_date_new} ${start_time}`,
		"YYYY/MM/DD HH:mm"
	);
	const endDateTime = moment(`${end_date_new} ${end_time}`, "YYYY/MM/DD HH:mm");
	return (
		(status == CONSTANTS.PERIOD_STATUS.PUBLICATION_TIME &&
			start_date &&
			start_time &&
			!moment().isBefore(startDateTime)) ||
		(end_date &&
			end_time &&
			specify_the_end &&
			!startDateTime.isBefore(endDateTime))
	);
}

const checkHours = (end_time, start_time, start_date, end_date) => {
	const converntTimeEnd = end_time ? end_time.split(":") : "";
	const converntTimeStart = start_time ? start_time.split(":") : "";
	const convertDDMMYYYYEnd = moment(end_date).valueOf();
	const timeEnd = moment()
		.set({
			hours: converntTimeEnd[0],
			minute: converntTimeEnd[1],
		})
		.valueOf();
	const convertDDMMYYYYStart = moment(start_date).valueOf();

	const timeStart = moment()
		.set({
			hours: converntTimeStart[0],
			minute: converntTimeStart[1],
		})
		.valueOf();
	const totalTimeStart = convertDDMMYYYYStart + timeStart;
	const totalTimeEnd = convertDDMMYYYYEnd + timeEnd;
	if (convertDDMMYYYYEnd < convertDDMMYYYYStart) {
		return true;
	} else if (convertDDMMYYYYEnd === convertDDMMYYYYStart) {
		if (totalTimeStart > totalTimeEnd) {
			return true;
		}
	}
	return false;
};

function compareObjects(one, two) {
	return JSON.stringify(one) === JSON.stringify(two);
}

const getDateJP = () => {
	const date = new Date();
	// Định dạng ngày giờ theo múi giờ Nhật Bản
	const options = { timeZone: 'Asia/Tokyo' };
	const japanTime = date.toLocaleString('en-US', options);
	const month = moment(japanTime).format('MM');
	const year = moment(japanTime).format('yyyy');  
	return {monthJA : month , yearJA: year};
}
const getMonthCard = (year) => {
	let month = CONSTANTS.MONTH ;    
	const index = CONSTANTS.MONTH.findIndex( e => e.value === getDateJP().monthJA);
	if (index !== -1 && year === Number(getDateJP().yearJA)) {
		for( let i = 0 ; i < index ; i++ ){
			month[i].disabled = true;
		}
	} else {
		month = CONSTANTS.MONTH ;
	}     
	return month ;
}
const getYearCard = (month) => {
	let max = new Date().getFullYear();
	const years = [];
	let check = true ;
	if (month !== '') {
		check = month >= getDateJP().monthJA;
	}     
	for (let i = max + 8; i >= max; i--) {
		const obj = {
			text: check ? Number(i.toString().substr(-2)) : Number(i.toString().substr(-2)) + 1,
			value:check ? Number(i) : Number(i)+1,
		};
		years.push(obj);
	}
	return years;
}
const calculateTotalStatus = (desserts) => {
	return desserts.reduce((acc, curr) => {
		if (curr.status === 2 || curr.status === 3) {
			return acc + 1;
		} else {
			return acc;
		}
	}, 0);
}

function formattedNumber(num) {
  if (num === undefined || num === null) {
    return 0;
  }
  return num.toLocaleString("en-US");
}

const getIndexValue = (index) => {
	switch (index) {
		case 0:
			return "A";
		case 1:
			return "B";
		case 2:
			return "C";
		case 3:
			return "D";
		case 4:
			return "E";
		case 5:
			return "F";
		case 6:
			return "G";
		case 7:
			return "H";
		case 8:
			return "I";
		case 9:
			return "J";
		default:
			return null;
	}
}

const handleDisplayPositionMap = (item, routeID) => {
	const index = item.findIndex(
		(element) => element.id === routeID
	);
	if (index == 0) {
		return "A";
	}
	if (index == 1) {
		return "B";
	}
	if (index == 2) {
		return "C";
	}
	if (index == 3) {
		return "D";
	}
	if (index == 4) {
		return "E";
	}
	if (index == 5) {
		return "F";
	}
	if (index == 6) {
		return "G";
	}
	if (index == 7) {
		return "H";
	}
	if (index == 8) {
		return "I";
	}
	if (index == 9) {
		return "J";
	}
}

const base64ToFile = (base64String, fileName, mimeType) => {
	const byteString = atob(base64String.split(',')[1]);

	const byteArray = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) {
		byteArray[i] = byteString.charCodeAt(i);
	}
  
	return new File([byteArray], fileName || `${Date.now()}.png`, { type: mimeType || "image/png" });
  }

export {
	errorFileName,
	errorAudioFileName,
	getMonthCard,
	getYearCard,
	getDateJP,
	COLLECTION_FIREBASE,
	URLS,
	API,
	CONSTANTS,
	REGEX,
	handleGetMessageError,
	handleGetIsShowMessageError,
	authRouter,
	restrictRoute,
	auth,
	readFileXLSX,
	exportXLSX,
	imageSize,
	getSizeFile,
	getTimes,
	getNextTime,
	getTimeItems,
	formatDate,
	formatDateTime,
	exportAOA,
	scrollToTop,
	validatePublicTimeDate,
	compareObjects,
	getStatusTime,
	convertByteToGB,
	checkHours,
	encryptedUrl,
	decryptedUrl,
	downLoadLoginRate,
	CapacityUnit,
	calculateTotalStatus,
	getIndexValue,
	handleDisplayPositionMap,
	exportMultiSheet,
	exportCSV,
	formattedNumber,
	base64ToFile,
};
