<template>
  <v-app id="inspire">
    <v-app-bar app fixed>
      <v-app-bar-nav-icon @click="drawer = !drawer" />

      <v-toolbar-title>
        <div class="header-icon">
          <v-img
            transition="false"
            :src="require('@/assets/logo-pinto-game.svg')"
            class="my-3 cursor-pointer"
            @click="home"
          />
        </div>
      </v-toolbar-title>

      <v-spacer class="d-md-block d-none" />

      <v-menu :offset="{ y: true }" bottom location="left">
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>

          <div class="d-md-flex user-name-info d-md-block d-none">
            <div class="user-name-info__avatar">
              <v-avatar size="32">
                <v-icon>mdi-account-circle</v-icon>
              </v-avatar>
            </div>

            <div class="user-name-info__text">
              <small class="mb-0">
                {{ $t("label.user_id") }}: {{ auth?.user?.user_id }}
              </small>

              <p v-if="auth?.user?.full_name" class="mb-0">
                {{ auth?.user?.full_name }}
              </p>
            </div>
          </div>

          <div
            class="d-md-block d-none mr-2 font-weight-regular"
            style="color: #000000de"
          >
            <v-icon>mdi-office-building</v-icon>

            <span id="company_name" class="font-weight-regular"
              >{{ auth?.company?.company_name }}
            </span>
          </div>
        </template>

        <v-list>
          <v-list-item
            link
            :to="`/user-management/edit/${auth?.user?.user_id}`"
          >
            <v-list-item-title class="cursor-pointer">
              ユーザー情報
            </v-list-item-title>
          </v-list-item>

          <v-list-item link to="/account-info">
            <v-list-item-title class="cursor-pointer">
              {{ $t("title.account_info") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="handleLogout" class="cursor-pointer"
              >ログアウト</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app fixed>
      <div class="nav-custom">
        <div
          class="ma-2 rounded d-flex justify-space-between pa-3 grey lighten-4"
        >
          <div class="d-flex align-center font-weight-bold">
            <v-icon color="black" class="mx-2">mdi-play</v-icon>ブラウザで確認
          </div>

          <div class="d-flex align-center">
            <v-divider vertical class="mx-2" />
            <v-icon>mdi-qrcode</v-icon>
          </div>
        </div>

        <v-list value nav density="default" v-model:selected="selectedItem">
          <!-- <v-list-item-group v-model="selectedItem" color="primary"> -->
          <v-list-item v-for="(item, i) in items" :key="i" :to="item.path">
            <v-list-item :icon="item.icon">
              <!-- <v-icon v-text="item.icon"></v-icon> -->
            </v-list-item>

            <!-- <v-list-item-content> -->
            <v-list-item-title v-text="item.text" ></v-list-item-title>
            <!-- </v-list-item-content> -->
          </v-list-item>
          <!-- </v-list-item-group> -->
        </v-list>
        <div class="ma-5">
          <div class="data-usage-info">
            <span>{{ $t("title.data_capacity") }} : 30.1%</span>
            <v-progress-linear value="30" color="secondary"></v-progress-linear>
            <span>9.03GB / 30GB</span>
          </div>
          <div class="data-usage-info">
            <span>{{ $t("title.monthly_data_transfer_volume") }} : 50.5%</span>
            <v-progress-linear value="50" color="secondary"></v-progress-linear>
            <span>15.15GB / 30GB</span>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
    <v-main class="bg-imge-main">
      <v-container fluid>
        <p-breadcrumbs />
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { auth } from "@/utils";
import { authStore } from "@/store";
import { mapState } from "pinia";
export default {
  name: "CompanyLayout",
  setup() {
    const storeAuth = authStore();
    return {
      storeAuth,
    };
  },
  data() {
    return {
      selectedItem: [0],
      items: [
        {
          text: "nav.dashboard",
          icon: "mdi-view-dashboard",
          path: "/",
        },
        {
          text: this.$t("nav.game_management"),
          icon: "mdi-gamepad-variant",
          path: "/game-management",
        },
        {
          text: this.$t("nav.notification_management"),
          icon: "mdi-bell",
          path: "/notification-management",
        },
        {
          text: this.$t("nav.recommendation_management"),
          icon: "mdi-newspaper-variant",
          path: "/recommendation-management",
        },
        // {
        //   text: this.$t("nav.recommendation"),
        //   icon: "mdi-hand-heart",
        //   path: "/recommendation-management",
        // },
        {
          text: this.$t("nav.login_rate"),
          icon: "mdi-poll",
          path: "/login-rate",
        },
        {
          text: this.$t("nav.score_history"),
          icon: "mdi-chart-timeline-variant",
          path: "/score-history",
        },
        {
          text: this.$t("nav.user_management"),
          icon: "mdi-account",
          path: "/user-management",
        },
        // {
        //   text: this.$t("nav.ip_restrictions"),
        //   icon: "mdi-ip",
        //   path: "/ip-restrictions",
        // },
      ],
      drawer: null,
    };
  },
  computed: {
    ...mapState(authStore, ["success"]),
    auth() {
      return auth();
    },
  },
  methods: {
    home() {
      if (this.$route.path != "/") {
        this.$router.push({ path: "/" });
      }
    },
    handleReload(path) {
      if (this.$route.path == path) {
        location.reload();
      }
    },
    async handleLogout() {
      await this.storeAuth.logout();
      if (this.success) {
        this.$router.push({
          name: "Login",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  .pg-container {
    padding: 0 24px;
  }

  header {
    z-index: 10 !important;

    &.v-app-bar {
      left: 0 !important;
    }

    .header-icon {
      width: 165px;
    }

    .user-name-info {
      padding: 10px;

      &__avatar {
        margin-right: 5px;
        display: flex;
        align-items: center;
      }
    }
  }

  nav {
    &.v-navigation-drawer {
      .nav-custom {
        margin-top: 80px;

        .v-list {
          .v-list-item {
            &__title {
              font-size: 1rem;
              font-weight: 400;
              line-height: 19px;
            }

            &__text {
              small {
                font-size: 14px;
                font-weight: 400;
                color: #00000099;
              }

              p {
                font-weight: 400;
                color: #000000de;
              }
            }
          }
        }
      }
    }
  }
}
</style>
